import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import ShareLinks from './ShareLinks'
import {GatsbyImage} from "gatsby-plugin-image"
import moment from 'moment'

class StrapiNewsCard extends React.Component { 
  constructor(props) {
    super()

    this.post = props.post
    this.state = {
      dropdownState: false,
    }

    this.toggleShare = this.toggleShare.bind(this)
  }

  toggleShare() {
    this.setState({dropdownState: !this.state.dropdownState})
  }

  slugify(text) {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
    const to = "aaaaaeeeeeiiiiooooouuuunc------"

    const newText = text.split('').map(
      (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

    return newText
      .toString()                     // Cast to string
      .toLowerCase()                  // Convert the string to lowercase letters
      .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
      .trim()                         // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/&/g, '-y-')           // Replace & with 'and'
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-');        // Replace multiple - with single -
  }

  render() {
    let post = this.post
    let slug = this.slugify(post.title)
    let date = moment(post.publishedAt).format("YYYY-MM-DD")
    let date_formatted = moment(post.publishedAt).format("MMMM DD, YYYY")
    let path = `/blog/${date}-${slug}`
    let hasExternal = post.externalLink !== null



  
  return (
  <div className="news-preview-item" key={post.id}>
    { hasExternal ? (
      <a href={post.externalLink}>
        <GatsbyImage image={post.heroImage.localFile.childImageSharp.gatsbyImageData} alt="" style={{ width: '100%', height: '154px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}} />
      </a>
    ): (
      <Link to={path}>
        <GatsbyImage image={post.heroImage.localFile.childImageSharp.gatsbyImageData} alt="" style={{ width: '100%', height: '154px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}} />
      </Link>
    )}
    <div className="news-preview-content">
    <p className="is-size-5">
      { hasExternal ? (
        <a href={post.externalLink}>{post.title}</a>
      ): (
        <Link to={path}>{post.title}</Link>
      )}
      <br /><small className="accent-color">{date_formatted}</small></p>
    </div>
    <div className="news-preview-footer">
      { hasExternal ? (
        <a className="button is-white" href={post.externalLink}>Read More</a>
      ): (
      <>
      <Link className="button is-white" to={path}>Read More</Link>
      <div class={'dropdown is-hoverable is-up '+(this.state.dropdownState?'is-active':'')}>
        <div class="dropdown-trigger">
          <button onClick={this.toggleShare} class="button is-white" aria-haspopup="true" aria-controls="dropdown-menu4">
            <span>Share</span>
            <span className="icon is-small">
            <FontAwesomeIcon icon={faAngleUp} />
            </span>
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu4" role="menu">
          <div class="dropdown-content">
            <ShareLinks slug={path} />
          </div>
        </div>
      </div>
      </>
      )}
    </div>
  </div>
)
}
}

export default StrapiNewsCard