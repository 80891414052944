import React from 'react'

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
    let email
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  
    return (
      <div>
        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <div className="field">
        <div className="control">
        <input
          className="input"
          ref={node => (email = node)}
          type="email"
          placeholder="Your email"
        />
        </div>
        </div>
        <div className="control">
        <button className="button is-primary" onClick={submit}>
          Submit
        </button>
        </div>
      </div>
    );
  };

export default CustomForm;