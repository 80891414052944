import React from 'react'
import PropTypes from 'prop-types'
import {Link, StaticQuery, graphql} from 'gatsby'
import Image  from 'gatsby-image'
import {HTMLContent} from './Content'
import {remark} from 'remark'
import remarkHtml from 'remark-html'
import NewsPreview from './NewsPreview'
import accent from '../img/accent2.svg'
import arrow from '../img/arrow.svg'
import NavbarTransparent from './NavbarTransparent'
import Footer from './Footer'
import Helmet from 'react-helmet'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import CustomForm from './CustomForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import ImageBar from './ImageBarCarosel'
  
const HomePageTemplate = ({
  welcomeMessage,
  welcomeAside,
  nineEleven
}) => (
  < >
    <Helmet title="Westlake Municipal Utility District 1" />
    <Helmet>
      <meta name="google-site-verification" content="Qoxj4Hlzxo9ST0EsOtRgfo8wnSStUJIgr_Bo3HEibeI" />
    </Helmet>
    <div className="">
    <NavbarTransparent />
    <section className="section__home-page">
        <ImageBar />
        <div className="container">
        <div className="columns accent-offset ">
          <div className="column is-3">
            <Link to="/latest-updates" className="blog-button" style={{ float: 'right', marginBottom: '2rem' }}>
                <img src={arrow} alt="" /><br />
                SEE ALL<br />
                LATEST UPDATES
              </Link>
              <div className="card" style={{ marginTop: '2rem', padding: '1rem', clear: 'right'}}>
                <h3 className="is-size-4" style={{ marginBottom: '1rem'}}>Signup to receive updates!</h3>
                <MailchimpSubscribe
                  url="https://touchstonedistrictservices.us19.list-manage.com/subscribe/post?u=9e9660a8cc948aa82bf3acc40&amp;id=d3fe43778f"
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      message={message}
                      onValidated={formData => subscribe(formData)}
                    />
                  )}
                />
            </div>
          </div>
          <div className="column is-9">
            <NewsPreview />
          </div>
        </div>
      </div>
        <div className={`nineElevenBanner ${new Date().getMonth() === 8 ? 'active': ''}`}>
          <div className="container">
            <div className="columns">
              <div className="column is-6 is-offset-3">
                <Image fluid={nineEleven.childImageSharp.fluid} alt="" />
              </div>
            </div>
          </div>
        </div>
    </section>
    </div>
    <section className="section">
      <div className="container">
      <div className="columns">
        <div className="column is-6 is-offset-1 ">
          <div className="content">
            <HTMLContent content={remark().use(remarkHtml).processSync(welcomeMessage).toString()}/>
          </div>
        </div>
        <div className="column is-3 is-offset-1">
          <p><Link class="button is-primary" to="/board-of-directors-elections">Board of Directors Elections</Link></p>
          <p><Link class="button is-primary" to="/es/board-of-directors-elections">Elecciones de la Junta Directiva</Link></p>
          <p><Link class="button is-primary" to="/vi/board-of-directors-elections">Các Cuộc Bầu Cử Ban Giám Đốc</Link></p>
          <p><Link class="button is-primary" to="/zh/board-of-directors-elections">董事會選舉</Link></p>
          {/* <Image className="asideImage" fixed={welcomeAside.childImageSharp.fixed} alt="" /> */}
        </div>
      </div>
      </div>
    </section>
    <Footer />
  </ >
)

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  welcomeMessage: PropTypes.string,
}

export default HomePageTemplate
